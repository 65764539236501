:root {
    --fc-daygrid-event-dot-width: 10px;

    --fc-list-event-dot-width: 10px;
    --fc-list-event-hover-bg-color: #f5f5f5;

    --fc-border-color: #dddddd;
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: transparent;

    font-size: 14px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

table {
    box-shadow: 4px 4px 11px #00000024;
}

.container {
    padding: 0 5rem;
    height: 100vh;
    max-height: 100vh;
}

.calendar {
    height: 100%;
    padding: 2rem;
}

.scheduledEvent {
    overflow: hidden;
    height: 100%;
    max-height: 100%;

    .title {
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        &.shortEvent30,
        &.shortEvent15 {
            flex-direction: row;
            justify-content: space-between;

            .titleText {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.shortEvent15 {
            font-size: 10px;

            .titleText {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .titleText {
            display: flex;

            .eventColor {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                display: flex;
                margin-right: .5rem;
            }
        }
    }
}


// CUSTOM CSS
.fc {
    height: 90vh;
}

.fc-icon-fa {
    font-family: FontAwesome;
}

.fc-scroller {
    overflow: hidden;

}

.fc .fc-toolbar-title {
    font-size: 1.5rem;
}

.fc .fc-timegrid-slot {
    height: 45px;
}

.fc .fc-timegrid-slot-minor {
    border: none;
}

.fc .fc-timegrid-slot-label {
    border: none;
    display: flex;
    justify-content: flex-end
}

.fc .fc-timegrid-slot-label-cushion {
    display: flex;
    margin-top: -8px;
    font-size: 12px;
    color: #a5a5a5;
    text-transform: uppercase;
}

.fc tr:first-child .fc-timegrid-slot-label {
    display: none;
}

.fc .fc-toolbar-title {
    font-weight: 400;
    color: #555;
}

.fc-event {
    border-width: 3px;
}

.fc-v-event {
    border: none;
    padding: 0 .2rem;
}

.customDayWeek {
    padding: .5rem;
    display: flex;
    flex-direction: column;
    gap: .2rem;

    .numDay {
        font-size: .7rem;
        font-weight: 500;
        text-transform: uppercase;
        color: #868686;
    }

    .weekDay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        font-size: 1.2rem;
        font-weight: 400;
        color: #555;

        &.isToday {
            background-color: var(--fc-event-bg-color);
            color: #FFFFFF;
        }
    }
}

.fc .fc-button-primary {
    background: #f3f3f3;
    border-radius: 10px;
    border: none;
    color: #555;
    padding: .5rem 1rem;
    transition: all 200ms ease-in-out;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        width: 0;
        transition: width 200ms ease;
    }

    &:hover(:not(disabled)) {
        &:after {
            content: '';
            height: 2px;
            width: 100%;
            background-color: #3788d8;
        }
    }

    &:disabled {
        background-color: #f3f3f3;
        border: 1px solid #d4d4d4;
        color: #bdbdbd;
    }

}

.fc-button-group {
    border-radius: 10px;
    box-shadow: 1px 3px 4px 0px #0000002e;
}

.fc .fc-button-primary,
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:hover {
    border: 1px solid #d4d4d4;
}


.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active,
button.fc-today-button.fc-button.fc-button-primary {
    border-radius: 10px;
    // border: 0;
    background: #3788d8;
    color: #FFF;
}

button.fc-timeGridDay-button.fc-button.fc-button-primary:hover.fc-button-active,
button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active,
button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
    background: #3788d8;
    color: #FFF;
    opacity: .8;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:focus {
    box-shadow: none;
}

.fc .fc-button-primary:hover:not([disabled], .fc-today-button),
.fc .fc-button-primary:hover:not([disabled], .fc-today-button):focus-visible {
    background: #f3f3f3;
    color: #555;
    outline: none;
}

.fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

}

.fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.fc-dayGridMonth-view tbody * {
    overflow: hidden;
}

.fc-popover {
    // margin-bottom: 100px;
    bottom: 0;
    .fc-popover-body {
        max-height: calc(100% - 2rem);
        overflow: auto;
    }
}