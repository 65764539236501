.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000056;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        background: #ffffff;
        padding: 1rem;
        border-radius: 10px;
        min-width: 320px;
        max-width: 80vh;
        max-height: 80vh;

        .header {
            border-bottom: 1px solid #00000011;
            padding-bottom: .3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                font-size: .8rem;
                text-transform: uppercase;
                font-weight: 400;
                color: #363636;
            }

            .closeModal {
                position: relative;
                background: transparent;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                margin-left: auto;
                cursor: pointer;

                &::after,
                &::before {
                    content: ' ';
                    position: absolute;
                    height: 1rem;
                    width: 2px;
                    background: #363636;
                }

                &::after {
                    transform: rotate(45deg);
                }

                &::before {
                    transform: rotate(-45deg);
                }
            }
        }

        .content {
            padding-top: 2rem;

            button {
                display: block;
                background-color: #3788d8;
                border: none;
                color: #FFFFFF;
                padding: .5rem 2rem;
                border-radius: 5px;
                margin-top: 1rem;
                margin-left: auto;
                transition: all 400ms;
                cursor: pointer;

                &:hover {
                    background: #2a68a7;
                }
            }

            .group {
                position: relative;
    
                input {
                    font-size: 18px;
                    padding: 10px 10px 10px 5px;
                    display: block;
                    width: 300px;
                    border: none;
                    border-bottom: 1px solid #757575;
    
                    &:focus {
                        outline: none;
                    }
                }
    
                label {
                    color: #999;
                    font-size: 18px;
                    font-weight: normal;
                    position: absolute;
                    pointer-events: none;
                    left: 5px;
                    top: 10px;
                    transition: 0.2s ease all;
                    -moz-transition: 0.2s ease all;
                    -webkit-transition: 0.2s ease all;
                }
    
                input:focus~label,
                input:valid~label {
                    top: -20px;
                    font-size: 14px;
                    color: #3788d8;
                }
    
                .bar {
                    position: relative;
                    display: block;
                    width: 300px;
    
                    &::before,
                    &::after {
                        content: '';
                        height: 2px;
                        width: 0;
                        bottom: 1px;
                        position: absolute;
                        background: #3788d8;
                        transition: 0.2s ease all;
                        -moz-transition: 0.2s ease all;
                        -webkit-transition: 0.2s ease all;
                    }
    
                    &::before {
                        left: 50%;
                    }
    
                    &::after {
                        right: 50%;
                    }
                }
    
                input:focus~.bar:before,
                input:focus~.bar:after {
                    width: 50%;
                }
    
                .highlight {
                    position: absolute;
                    height: 60%;
                    width: 100px;
                    top: 25%;
                    left: 0;
                    pointer-events: none;
                    opacity: 0.5;
                }
    
                input:focus~.highlight {
                    -webkit-animation: inputHighlighter 0.3s ease;
                    -moz-animation: inputHighlighter 0.3s ease;
                    animation: inputHighlighter 0.3s ease;
                }
    
                @-webkit-keyframes inputHighlighter {
                    from {
                        background: #3788d8;
                    }
    
                    to {
                        width: 0;
                        background: transparent;
                    }
                }
    
                @-moz-keyframes inputHighlighter {
                    from {
                        background: #3788d8;
                    }
    
                    to {
                        width: 0;
                        background: transparent;
                    }
                }
    
                @keyframes inputHighlighter {
                    from {
                        background: #3788d8;
                    }
    
                    to {
                        width: 0;
                        background: transparent;
                    }
                }
            }

            .list {
                position: relative;
                z-index: 99;
                ul {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    list-style: none;
                    height: 200px;
                    overflow-y: auto;

                    li {
                        background-color: #ffffff;
                        padding: 1rem;
                        cursor: pointer;
                        
                        &:nth-child(odd) {
                            background-color: #e0e0e0;
                        }
                    }
                }

            }
        }


                    // button {
            //     display: block;
            //     background-color: #3788d8;
            //     border: none;
            //     color: #FFFFFF;
            //     padding: .5rem 2rem;
            //     border-radius: 5px;
            //     margin-top: 1rem;
            //     margin-left: auto;
            //     transition: all 400ms;
            //     cursor: pointer;

            //     &:hover {
            //         background: #2a68a7;
            //     }
            // }
    }
}